<template>
  <c-grid class="gridcolumns" @grid:created="$emit('grid:created')" @grid:mounted="$emit('grid:mounted')" @grid:beforeDestroy="$emit('grid:beforeDestroy')">
    <template #header>
      <slot name="header"/>
    </template>

    <b-row class="gridcolumns-row" ref="content" :cols="contentCols.xs" :cols-sm="contentCols.sm" :cols-md="contentCols.md" :cols-lg="contentCols.lg" :cols-xl="contentCols.xl">
      <b-col v-for="sKey in contentSlotKeys" :key="sKey" :ref="sKey" class="gridcolumns-col">
        <slot :name="sKey"/>
      </b-col>
    </b-row>

    <template #footer>
      <slot name="footer"/>
    </template>
  </c-grid>
</template>

<script>
import { BASE_COLUMN_WIDTHS } from '@/assets/js/config/client'

const EXCLUDE_SCOPED_SLOTS = ['header', 'footer']

export default {
  name: 'GridColumns',
  computed: {
    contentCols () {
      return BASE_COLUMN_WIDTHS
    },
    contentSlotKeys () {
      return Object.keys(this.$scopedSlots || {})
        .filter(sKey => !EXCLUDE_SCOPED_SLOTS.includes(sKey))
    }
  }
}
</script>

<style lang="scss">
$gridcolumns-row-gap: $grid-gutter-width !default;

.gridcolumns {
  .gridcolumns-row {
    margin-top: $gridcolumns-row-gap * -0.5;
    margin-bottom: $gridcolumns-row-gap * -0.5;

    .gridcolumns-col {
      margin-top: $gridcolumns-row-gap * 0.5;
      margin-bottom: $gridcolumns-row-gap * 0.5;
    }
  }
}
</style>
